import { DEFAULT_PROFILE_IMAGE } from "../../services/constants";
import "./styles.scss";

const Avatar = ({ size = "25px", src }) => {
  return (
    <div className="avatar-wrapper" style={{ height: size, width: size }}>
      <img src={src || DEFAULT_PROFILE_IMAGE} alt="avatar" className="avatar-image" />
    </div>
  );
};

export default Avatar;
