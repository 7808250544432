import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";

import Logo from "../../components/Logo";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const BlankLayout = ({ children }) => {
  const { pathname } = useLocation();

  // console.log(pathname);

  const shouldHideNavbar =
    pathname === "/login" ||
    pathname === "/sign-up" ||
    pathname === "/forgot-password" ||
    pathname === "/verification" ||
    pathname === "/otpverification" ||
    pathname === "/userinfo";

  return (
    <div className="w-100 h-100 layout--blank">
      {shouldHideNavbar ? null : (
        <Navbar fixed="top" className="header">
          <Container fluid className="px-4">
            <Navbar.Brand as={RouterLink} to="/">
              <Logo maxWidth={"6rem"} />
            </Navbar.Brand>
          </Container>
        </Navbar>
      )}
      <Container fluid className="px-0 content">
        {children}
      </Container>
    </div>
  );
};

BlankLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlankLayout;
