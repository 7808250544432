const CACHE_VERSION = '1.0.0';
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

class CacheService {
  constructor() {
    this.loadCacheFromStorage();
  }

  loadCacheFromStorage() {
    try {
      const storedCache = localStorage.getItem('appCache');
      if (storedCache) {
        const parsedCache = JSON.parse(storedCache);
        if (parsedCache.version === CACHE_VERSION) {
          // Clear expired items
          const now = Date.now();
          this.cache = Object.entries(parsedCache.data).reduce((acc, [key, value]) => {
            if (value.timestamp + CACHE_EXPIRY > now) {
              acc[key] = value;
            }
            return acc;
          }, {});
        } else {
          this.cache = {};
        }
      } else {
        this.cache = {};
      }
    } catch (error) {
      console.error('Error loading cache:', error);
      this.cache = {};
      localStorage.removeItem('appCache');
    }
  }

  saveCache() {
    try {
      localStorage.setItem('appCache', JSON.stringify({
        version: CACHE_VERSION,
        data: this.cache
      }));
    } catch (error) {
      console.error('Error saving cache:', error);
    }
  }

  get(key) {
    const cachedItem = this.cache[key];
    if (!cachedItem) return null;

    if (cachedItem.timestamp + CACHE_EXPIRY < Date.now()) {
      delete this.cache[key];
      this.saveCache();
      return null;
    }

    return cachedItem.data;
  }

  set(key, data) {
    this.cache[key] = {
      data,
      timestamp: Date.now()
    };
    this.saveCache();
  }

  clear() {
    this.cache = {};
    localStorage.removeItem('appCache');
  }
}

// Create single instance of CacheService
const cacheService = new CacheService();

export default cacheService;
export { CacheService };

// Export cache keys
export const CACHE_KEYS = {
  ON_SALE: 'onSale',
  FOR_YOU: 'forYou',
  WINTER_PICKS: 'winterPicks',
  RECOMMENDED_BRANDS: 'recommendedBrands',
  RECOMMENDED_LISTS: 'recommendedLists',
  PROFILE_RECOMMENDATIONS: 'profileRecommendations',
};