import { data } from "jquery";
import ENDPOINTS, {
  DELETE,
  GET,
  PATCH,
  POST,
  PUT,
} from "../../services/requests";
import { resetSnack, showSnack } from "../actions/alertActions";
import {
  CreateYourListsBegin,
  CreateYourListsFailure,
  CreateYourListsSuccess,
  DeleteYourListBegin,
  DeleteYourListFailure,
  DeleteYourListSuccess,
  addProductToAnotherListBegin,
  addProductToAnotherListFailure,
  addProductToAnotherListSuccess,
  createUserGoalReportBegin,
  createUserGoalReportFailure,
  createUserGoalReportSuccess,
  createUserReportBegin,
  fetchProductTagsBegin,
  fetchProductTagsFailure,
  fetchProductTagsSuccess,
  createUserReportFailure,
  createUserReportSuccess,
  createWardrobeBegin,
  createWardrobeFailure,
  createWardrobeSuccess,
  deleteProductBegin,
  deleteProductFailure,
  deleteProductSuccess,
  deleteUserBegin,
  deleteUserFailure,
  deleteUserSuccess,
  deleteWardrobeBegin,
  deleteWardrobeFailure,
  deleteWardrobeItemByidBegin,
  deleteWardrobeItemByidFailure,
  deleteWardrobeItemByidSuccess,
  deleteWardrobeSuccess,
  duplicateListBegin,
  duplicateListFailure,
  duplicateListSuccess,
  fetchSavedProductBegin,
  fetchSavedProductFailure,
  fetchSavedProductSuccess,
  fetchWardrobeBegin,
  fetchWardrobeByIdBegin,
  fetchWardrobeByIdFailure,
  fetchWardrobeByIdSuccess,
  fetchWardrobeFailure,
  fetchWardrobeSuccess,
  getCategoryItemsSelectedBegin,
  getCategoryItemsSelectedFailure,
  getCategoryItemsSelectedSuccess,
  fetchProductsByCategoriesBegin,
  fetchProductsByCategoriesFailure,
  fetchProductsByCategoriesSuccess,
  getBrandsBegin,
  getBrandsSuccess,
  getBrandsFailure,
  updateSelectBrandsBegin,
  updateSelectBrandsSuccess,
  updateSelectBrandsFailure,
  getDetailsBegin,
  getDetailsFailure,
  getDetailsSuccess,
  getFetchUserProfileBegin,
  getFetchUserProfileFailure,
  getFetchUserProfileSuccess,
  getGeneratedReportsListBegin,
  getGeneratedReportsListFailure,
  getGeneratedReportsListSuccess,
  getGoalsBegin,
  getGoalsFailure,
  getGoalsSuccess,
getItemDetailsBegin,
  getItemDetailsFailure,
  getItemDetailsSuccess,
  getItemNotificationSettingFailure,
  getItemNotificationSettingSuccess,
  getListBegin,
  getListFailure,
  getListSuccess,
  getNotificationSettingBegin,
  getOnboardingInterestsBegin,
  getOnboardingInterestsFailure,
  getOnboardingInterestsSuccess,
  getReportsSignedUrlBegin,
  getReportsSignedUrlFailure,
  getReportsSignedUrlSuccess,
  getTimelineBegin,
  getTimelineFailure,
  getTimelineSuccess,
  getYourListsBegin,
  getYourListsFailure,
  getYourListsSuccess,
  likeListBegin,
  likeListFailure,
  likeListSuccess,
  likeProductBegin,
  likeProductFailure,
  likeProductSuccess,
  postItemNotificationSettingFailure,
  postItemNotificationSettingSuccess,
  postNotificationSettingBegin,
  updateItemWardrobeBegin,
  updateItemWardrobeFailure,
  updateItemWardrobeSuccess,
  updateSelectCategoryItemsBegin,
  updateSelectCategoryItemsFailure,
  updateSelectCategoryItemsSuccess,
  updateUserBegin,
  updateUserFailure,
  updateUserSuccess,
  updateYourListBegin,
  updateYourListFailure,
  updateYourListSuccess,
  uploadImageWardrobeBegin,
  uploadImageWardrobeFailure,
  uploadImageWardrobeSuccess,
  getExploreTagsBegin,
  getExploreTagsSuccess,
  getExploreTagsFailure,
  getFollowUserPublicListBegin,
  getFollowUserPublicListSuccess,
  getFollowUserPublicListFailure,
  updateListAccessBegin,
  updateListAccessSuccess,
  updateListAccessFailure,
  getUserEarningBegin,
  getUserEarningSuccess,
  getUserEarningFailure,
  getUserReferralBegin,
  getUserReferralSuccess,
  getUserReferralFailure,
  fetchTopBrandsUserBegin,
  fetchTopBrandsUserSuccess,
  fetchTopBrandsUserFailure,
  fetchTopBrandsGuestBegin,
  fetchTopBrandsGuestSuccess,
  fetchTopBrandsGuestFailure,
  fetchForYouUserBegin,
  fetchForYouUserSuccess,
  fetchForYouUserFailure,
  fetchForYouGuestBegin,
  fetchForYouGuestSuccess,
  fetchForYouGuestFailure,
  getFetchUserOutfitListBegin,
  getFetchUserOutfitListSuccess,
  getFetchUserOutfitListFailure,
  fetchSavedProductPurchaseHistoryFailure,
  fetchSavedProductPurchaseHistorySuccess,
  fetchSavedProductPurchaseHistoryBegin,
  PostSavedProductPurchaseHistorySuccess,
  PostSavedProductPurchaseHistoryFailure,
  PostSavedProductPurchaseHistoryBegin,
  PostRemoveSavedProductPurchaseHistoryBegin,
  PostRemoveSavedProductPurchaseHistorySuccess,
  PostRemoveSavedProductPurchaseHistoryFailure,
  addProductBegin,
  addProductSuccess,
  addProductFailure,
  fetchCartBegin,
  fetchCartSuccess,
  fetchCartFailure,
  fetchAllProductBegin,
  fetchAllProductSuccess,
  fetchAllProductFailure,
  markItemAsPurchasedBegin,
  markItemAsPurchasedSuccess,
  markItemAsPurchasedFailure,
  fetchGiftListBegin,
  fetchGiftListSuccess,
  fetchGiftListFailure,
  addToGiftListBegin,
  addToGiftListSuccess,
  addToGiftListFailure,
  removeFromGiftListBegin,
  removeFromGiftListSuccess,
  removeFromGiftListFailure,
  getListRecommendationsBegin,
  getListRecommendationsSuccess,
  getListRecommendationsFailure,
  getProfileRecommendationsBegin,
  getProfileRecommendationsSuccess,
  getProfileRecommendationsFailure,
  fetchRecommendationsBegin,
  fetchRecommendationsFailure,
  fetchRecommendationsSuccess,
  fetchRecommendedBrandsBegin,
  fetchRecommendedBrandsSuccess,
  fetchRecommendedBrandsFailure,
  searchUniversitiesBegin,
  searchUniversitiesSuccess,
  searchUniversitiesFailure,
} from "../actions/userActions";
import cacheService, { CACHE_KEYS } from "../../services/cacheContext";

// Get Onboarding Interests
export function performGetOnboardingInterests(data) {
  return async (dispatch) => {
    dispatch(getOnboardingInterestsBegin());
    try {
      const result = await GET({ url: ENDPOINTS.ONBOARDING_INTERESTS, data });
      dispatch(getOnboardingInterestsSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(getOnboardingInterestsFailure());
      return false;
    }
  };
}

// Get Brands
export function performGetBrands() {
  return async (dispatch) => {
    dispatch(getBrandsBegin());
    try {
      const result = await GET({ url: ENDPOINTS.BRANDS });
      dispatch(getBrandsSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(getBrandsFailure());
      return false;
    }
  };
}

// Update Selected Brands 
export function performUpdateSelectBrands(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(updateSelectBrandsBegin());
    try {
      const result = await PUT({
        url: ENDPOINTS.UPDATE_BRANDS,
        data: {
          brands: data,
        },
      });
      dispatch(updateSelectBrandsSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return true;
    } catch (error) {
      dispatch((updateSelectBrandsFailure));
      return false;
    }
  };
}

export function performGetCategoryItemsSelected(data) {
  return async (dispatch) => {
    dispatch(getCategoryItemsSelectedBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.CATEGORY_ITEMS_SELECTED,
        data,
      });
      dispatch(getCategoryItemsSelectedSuccess(result.data));
      return result.data;
    } catch (error) {
      dispatch(getCategoryItemsSelectedFailure());
      return false;
    }
  };
}

export function performUpdateSelectCategoryItems(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(updateSelectCategoryItemsBegin());
    try {
      const result = await PUT({
        url: ENDPOINTS.SELECT_CATEGORY_ITEMS,
        data: {
          categoryItemId: data,
        },
      });
      dispatch(updateSelectCategoryItemsSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return true;
    } catch (error) {
      dispatch(updateSelectCategoryItemsFailure());
      return false;
    }
  };
}

// GET_PRODUCTS_BY_CATEGORIES
export function performGetProductsByCategories(data) {
  return async (dispatch) => {
    dispatch(fetchProductsByCategoriesBegin());
    try {
      // Cache for dashboard on-sale request
      const shouldCache = data?.filters?.isOnSale === true && data?.isDashboard === true;
      const cacheKey = shouldCache ? `${CACHE_KEYS.ON_SALE}-${JSON.stringify(data)}` : null;
      
      if (shouldCache) {
        const cachedData = cacheService.get(cacheKey);
        if (cachedData) {
          dispatch(fetchProductsByCategoriesSuccess(cachedData));
          return cachedData;
        }
      }

      const result = await POST({
        url: ENDPOINTS.GET_PRODUCTS_BY_CATEGORIES,
        data: data,
      });

      if (result && shouldCache) {
        cacheService.set(cacheKey, result);
      }
      
      dispatch(fetchProductsByCategoriesSuccess(result));
      return result;
    } catch (error) {
      console.error("Error in performGetProductsByCategories:", error);
      dispatch(fetchProductsByCategoriesFailure());
      return false;
    }
  };
}

//  FETCH_USER_PROFILE
export function performFetchUserDetail() {
  return async (dispatch) => {
    dispatch(getFetchUserProfileBegin());
    try {
      const result = await GET({ url: ENDPOINTS.FETCH_USER_PROFILE });

      dispatch(getFetchUserProfileSuccess(result.data));

      return result;
    } catch (error) {
      dispatch(getFetchUserProfileFailure(error));
      return false;
    }
  };
}
//  FETCH_PUBLIC/PRIVATE_OUTFIT_PROFILE
export function performFetchOutfitListsDetail(params) {
  return async (dispatch) => {
    dispatch(getFetchUserOutfitListBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_USER_OUTFIT_LISTS,
        params: params,
      });

      dispatch(getFetchUserOutfitListSuccess(result.data));

      return result;
    } catch (error) {
      dispatch(getFetchUserOutfitListFailure(error));
      return false;
    }
  };
}

//Your lists

export function performGetYourLists(params) {
  return async (dispatch) => {
    dispatch(getYourListsBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_YOUR_LISTS,
        params: params,
      });
      dispatch(getYourListsSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(getYourListsFailure());
      return false;
    }
  };
}

//Create_Your_list

export function performCreateYourLists(data) {
  return async (dispatch) => {
    dispatch(CreateYourListsBegin());
    try {
      const result = await POST({ url: ENDPOINTS.CREATE_YOUR_LISTS, data });
      dispatch(CreateYourListsSuccess(result.data));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return {
        status: true,
        data: result.data,
        message: result.message
      };
    } catch (error) {
      dispatch(CreateYourListsFailure());
      dispatch(
        showSnack({
          message: error.response?.data?.message || "Failed to create list. Please try again.",
          options: {
            variant: "error",
          },
        })
      );
      return {
        status: false,
        error: error.response?.data?.message || "Failed to create list"
      };
    }
  };
}

//Update Your Lists
export function performUpdateYourLists(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(updateYourListBegin());
    try {
      const result = await PATCH({
        url: ENDPOINTS.UPDATE_YOUR_LISTS(data.id),
        data: {
          title: data.title,
        },
      });
      dispatch(updateYourListSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(updateYourListFailure());
      return false;
    }
  };
}

//Update List Access
export function performUpdateListAccess(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(updateListAccessBegin());
    try {
      const result = await PATCH({
        url: ENDPOINTS.UPDATE_LIST_ACCESS(data.id),
        data: data.access,
      });
      dispatch(updateListAccessSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(updateListAccessFailure());
      return false;
    }
  };
}

//Delete Your Lists
export function performDeleteYourLists(id) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(DeleteYourListBegin());
    try {
      const result = await DELETE({
        url: ENDPOINTS.DELETE_YOUR_LISTS(id),
      });
      dispatch(DeleteYourListSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      dispatch(DeleteYourListFailure());
      return false;
    }
  };
}

// Duplicate List
export function performDuplicateList(data) {
  return async (dispatch) => {
    dispatch(duplicateListBegin());
    try {
      const result = await POST({ url: ENDPOINTS.DUPLICATE_LIST, data });
      dispatch(duplicateListSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(duplicateListFailure());
      return false;
    }
  };
}

// Like List
export function performLikeList(data) {
  return async (dispatch) => {
    dispatch(likeListBegin());
    try {
      const result = await POST({ url: ENDPOINTS.LIKE_LIST, data });
      dispatch(likeListSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(likeListFailure());
      return false;
    }
  };
}

export function performGetListRecommendations(params) {
  return async (dispatch) => {
    dispatch(getListRecommendationsBegin());
    try {
      const cacheKey = `${CACHE_KEYS.RECOMMENDED_LISTS}-${JSON.stringify(params)}`;
      const cachedData = cacheService.get(cacheKey);
      
      if (cachedData) {
        dispatch(getListRecommendationsSuccess(cachedData.data));
        return cachedData;
      }

      const result = await POST({
        url: ENDPOINTS.GET_LIST_RECOMMENDATIONS,
        params: params
      });
      
      if (result?.data) {
        cacheService.set(cacheKey, result);
        dispatch(getListRecommendationsSuccess(result.data));
      }
      return result;
    } catch (error) {
      dispatch(getListRecommendationsFailure(error));
      return false;
    }
  };
}

//GET Lists Items
export function performGetItemDetails(data) {
  let listId = data.id;
  let filters = data.filters;
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(getItemDetailsBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_ITEM_DETAILS(listId),
        data: {filters: filters},
      });
      dispatch(getItemDetailsSuccess());

      return result;
    } catch (error) {
      dispatch(getItemDetailsFailure());
      return false;
    }
  };
}

// Like Product
export function performLikeProduct(data) {
  return async (dispatch) => {
    dispatch(likeProductBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.LIKE_PRODUCT,
        data: data,
      });
      dispatch(likeProductSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(likeProductFailure());
      return false;
    }
  };
}

// DELETE Product
export function performDeleteProduct(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(deleteProductBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.DELETE_PRODUCT,
        data: data,
      });
      dispatch(deleteProductSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(deleteProductFailure());
      return false;
    }
  };
}

// Add Product To Another List

export function performAddProductToAnotherList(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(addProductToAnotherListBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.ADD_PRODUCT_TO_ANOTHER_LIST,
        data: data,
      });
      dispatch(addProductToAnotherListSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(addProductToAnotherListFailure());
      return false;
    }
  };
}

// FETCH_Cart_Popup
export function performFetchCartProduct(data) {
  return async (dispatch) => {
    dispatch(fetchCartBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_SAVED_PRODUCT,
        params: { ...data, filter_type: "Cart" },
      });
      dispatch(fetchCartSuccess(result));
      return result.data;
    } catch (error) {
      dispatch(fetchCartFailure());
      return false;
    }
  };
}

// ADD_PRODUCT
export function performAddProduct(data) {
  return async (dispatch) => {
    dispatch(addProductBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.ADD_PRODUCT,
        data,
      });
      dispatch(addProductSuccess(result));
      return result;
    } catch (error) {
      dispatch(addProductFailure());
      return false;
    }
  };
}

// ADD MULTIPLE PRODUCT INTO CART
export function performAddProducts(data) {
  return async (dispatch) => {
    try {
      const result = await POST({
        url: ENDPOINTS.ADD_PRODUCTS,
        data,
      });

      return result;
    } catch (error) {
      return false;
    }
  };
}

// FETCH_SAVED_PRODUCT
export function performFetchAllProduct(params) {
  return async (dispatch) => {
    dispatch(fetchAllProductBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_ALL_PRODUCT,
        params: params,
      });
      dispatch(fetchAllProductSuccess(result));
      return result;
    } catch (error) {
      dispatch(fetchAllProductFailure());
      return false;
    }
  };
}

// FETCH_PRODUCT_DETAILS
export function performFetchProductDetails(id) {
  return async (dispatch) => {
    try {
      const result = await GET({
        url: ENDPOINTS.GET_PRODUCT_DETAILS(id),
      });
      return result;
    } catch (error) {
      return false;
    }
  };
}

// fetch similar products
export function performSimilarProducts(params) {
  return async (dispatch) => {
    try {
      const result = await GET({
        url: ENDPOINTS.SIMILAR_PRODUCT,
        params: params,
      });
      return result;
    } catch (error) {
      return false;
    }
  };
}

// FETCH_SAVED_PRODUCT
export function performFetchSavedProduct(params) {
  return async (dispatch) => {
    dispatch(fetchSavedProductBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_SAVED_PRODUCT,
        params: params,
      });
      dispatch(fetchSavedProductSuccess(result));
      return result.data;
    } catch (error) {
      dispatch(fetchSavedProductFailure());
      return false;
    }
  };
}

// FETCH_SAVED_PRODUCT PURCHASE HISTORY
export function performFetchSavedProductPurchaseHistory(params) {
  return async (dispatch) => {
    dispatch(fetchSavedProductPurchaseHistoryBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_SAVED_PRODUCT_PURCHASE_HISTORY,
        params: params,
      });
      dispatch(fetchSavedProductPurchaseHistorySuccess(result));
      return result.data;
    } catch (error) {
      dispatch(fetchSavedProductPurchaseHistoryFailure());
      return false;
    }
  };
}

export function performRemoveSavedProductPurchaseHistory(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(PostRemoveSavedProductPurchaseHistoryBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY,
        data,
      });
      dispatch(PostRemoveSavedProductPurchaseHistorySuccess(result.data));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(PostRemoveSavedProductPurchaseHistoryFailure(error));
      return false;
    }
  };
}

export function performPostSavedProductPurchaseHistory(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(PostSavedProductPurchaseHistoryBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.POST_SAVED_PRODUCT_PURCHASE_HISTORY,
        data,
      });
      dispatch(PostSavedProductPurchaseHistorySuccess(result.data));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(PostSavedProductPurchaseHistoryFailure(error));
      return false;
    }
  };
}

// GET_USER_EARNING
export function performGetUserEarning() {
  return async (dispatch) => {
    dispatch(getUserEarningBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_USER_EARNING });
      dispatch(getUserEarningSuccess(result));
      return result;
    } catch (error) {
      dispatch(getUserEarningFailure());
      return false;
    }
  };
}

// GET_USER_REFERRAL
export function performGetUserReferral() {
  return async (dispatch) => {
    dispatch(getUserReferralBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_USER_REFERRAL });
      dispatch(getUserReferralSuccess(result));
      return result;
    } catch (error) {
      dispatch(getUserReferralFailure());
      return false;
    }
  };
}

// GET_NOTIFICATION_SETTING
export function performGetNotificationSetting() {
  return async (dispatch) => {
    dispatch(getNotificationSettingBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_NOTIFICATION_SETTING });
      dispatch(getItemNotificationSettingSuccess(result));
      return result;
    } catch (error) {
      dispatch(getItemNotificationSettingFailure());
      return false;
    }
  };
}

// UPDATE_NOTIFICATION_SETTING

export function performUpdateNotificationSetting(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(postNotificationSettingBegin());
    try {
      const result = await PATCH({
        url: ENDPOINTS.UPDATE_NOTIFICATION_SETTING,
        data,
      });
      dispatch(postItemNotificationSettingSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(postItemNotificationSettingFailure());
      return false;
    }
  };
}

export function performGetList(params) {
  return async (dispatch) => {
    dispatch(getListBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_USER_LIST, params });

      dispatch(getListSuccess(result.data));

      return result;
    } catch (error) {
      dispatch(getListFailure());
      return false;
    }
  };
}

export function performGetDetails(id) {
  return async (dispatch) => {
    dispatch(getDetailsBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_USER_DETAILS(id) });

      dispatch(getDetailsSuccess(result));

      return result;
    } catch (error) {
      dispatch(getDetailsFailure());
      return false;
    }
  };
}

export function performUpdateUser(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(updateUserBegin());
    try {
      const result = await PUT({ url: ENDPOINTS.UPDATE_USER(data.id), data });
      dispatch(updateUserSuccess());
      dispatch(
        showSnack({
          message: result.data,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(updateUserFailure());
      return false;
    }
  };
}

export function performDeleteUser(id, reason) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(deleteUserBegin());
    try {
      const result = await DELETE({
        url: ENDPOINTS.DELETE_USER(id),
        data: reason,
      });
      dispatch(deleteUserSuccess());
      dispatch(
        showSnack({
          message: result.data,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(deleteUserFailure());
      return false;
    }
  };
}

export function performGetUserGoals(params) {
  return async (dispatch) => {
    dispatch(getGoalsBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_GOAL_LIST, params });
      dispatch(getGoalsSuccess(result.data));

      return result;
    } catch (error) {
      dispatch(getGoalsFailure());
      return false;
    }
  };
}

export function performGetUserTimeline(payload) {
  return async (dispatch) => {
    dispatch(getTimelineBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_USER_TIMELINE(payload.id),
        params: payload.params,
        data: payload.data,
      });

      dispatch(getTimelineSuccess(result.data.response));

      return result;
    } catch (error) {
      dispatch(getTimelineFailure());
      return false;
    }
  };
}

export function performCreateUserReport(data) {
  return async (dispatch) => {
    dispatch(createUserReportBegin());
    try {
      const result = await POST({ url: ENDPOINTS.CREATE_USER_REPORT, data });
      dispatch(createUserReportSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(createUserReportFailure());
      return false;
    }
  };
}

export function performCreateUserGoalReport(payload) {
  return async (dispatch) => {
    dispatch(createUserGoalReportBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.CREATE_USER_GOAL_REPORT(payload.reportId),
        data: payload.bodyData,
      });
      dispatch(createUserGoalReportSuccess(result.data));

      return result;
    } catch (error) {
      dispatch(createUserGoalReportFailure());
      return false;
    }
  };
}

export function performGetGeneratedReportsList(payload) {
  return async (dispatch) => {
    dispatch(getGeneratedReportsListBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_GENERATED_REPORTS_LIST(payload.userId),
      });
      dispatch(getGeneratedReportsListSuccess(result.data));

      return result;
    } catch (error) {
      dispatch(getGeneratedReportsListFailure());
      return false;
    }
  };
}

export function performGetReportsSignedUrl(payload) {
  return async (dispatch) => {
    dispatch(getReportsSignedUrlBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_REPORTS_SIGNED_URL(payload.reportId),
      });

      dispatch(getReportsSignedUrlSuccess(result.data));

      return result;
    } catch (error) {
      dispatch(getReportsSignedUrlFailure());
      return false;
    }
  };
}

// Wardrobe

export function performCreateWardrobeOutfit(data) {
  return async (dispatch) => {
    dispatch(createWardrobeBegin());
    try {
      const result = await POST({ url: ENDPOINTS.WARDROBE_CREATE, data });
      dispatch(createWardrobeSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(createWardrobeFailure());
      return false;
    }
  };
}
export function performUploadImageWardrobeOutfit(data) {
  return async (dispatch) => {
    dispatch(uploadImageWardrobeBegin());
    try {
      const result = await POST({ url: ENDPOINTS.WARDROBE_IMAGE_UPLOAD, data });
      dispatch(uploadImageWardrobeSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(uploadImageWardrobeFailure());
      return false;
    }
  };
}
export function performUpdateItemWardrobeOutfit(id, data) {
  return async (dispatch) => {
    dispatch(updateItemWardrobeBegin());
    try {
      const result = await PUT({
        url: ENDPOINTS.WARDROBE_ITEM_UPDATE(id),
        data,
      });
      dispatch(updateItemWardrobeSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(updateItemWardrobeFailure());
      return false;
    }
  };
}

export function performGetFetchWardrobeOutfit(filterData) {
  return async (dispatch) => {
    dispatch(fetchWardrobeBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.WARDROBE_CREATE,
        params: filterData,
      });
      dispatch(fetchWardrobeSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(fetchWardrobeFailure());
      return false;
    }
  };
}
export function performGetFetchByIdWardrobeOutfit(id) {
  return async (dispatch) => {
    dispatch(fetchWardrobeByIdBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.WARDROBE_CREATE_BY_ID(id),
      });
      dispatch(fetchWardrobeByIdSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(fetchWardrobeByIdFailure());
      return false;
    }
  };
}
export function performGetFetchByIdWardrobeOutfitPublic(id) {
  return async (dispatch) => {
    dispatch(fetchWardrobeByIdBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.WARDROBE_PUBLIC_DETAILS(id),
      });
      dispatch(fetchWardrobeByIdSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(fetchWardrobeByIdFailure());
      return false;
    }
  };
}

export function performDeleteWardrobeOutfit(id) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(deleteWardrobeBegin());
    try {
      const result = await DELETE({
        url: ENDPOINTS.DELETE_WARDROBE_OUTFIT(id),
      });
      dispatch(deleteWardrobeSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(deleteWardrobeFailure());
      return false;
    }
  };
}

export function performGetFetchProductTags(params) {
  return async (dispatch) => {
    dispatch(fetchProductTagsBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.PRODUCT_TAGS,
        params: params,
      });
      dispatch(fetchProductTagsSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(fetchProductTagsFailure());
      return false;
    }
  };
}

export function performDeleteWardrobeItemByIdOutfit(data) {
  const id = data.wardrobeId;
  const itemId = data.itemId;
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(deleteWardrobeItemByidBegin());
    try {
      const result = await DELETE({
        url: ENDPOINTS.DELETE_WARDROBE_ITEM_BYID_OUTFIT(id, itemId),
      });
      dispatch(deleteWardrobeItemByidSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(deleteWardrobeItemByidFailure());
      return false;
    }
  };
}

// Explore Section APIs
export function performGetExploreTags(params) {
  return async (dispatch) => {
    dispatch(getExploreTagsBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.EXPLORE_TAGS,
        params: params,
      });
      dispatch(getExploreTagsSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(getExploreTagsFailure());
      return false;
    }
  };
}

export function performGetFollowUserPublicList(params) {
  return async (dispatch) => {
    dispatch(getFollowUserPublicListBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_FOLLOW_USER_PUBLIC_LIST,
        params: params,
      });
      dispatch(getFollowUserPublicListSuccess(result));
      return result;
    } catch (error) {
      dispatch(getFollowUserPublicListFailure());
      return false;
    }
  };
}
export function performGetFollowUserPublicListGuest(params) {
  return async (dispatch) => {
    dispatch(getFollowUserPublicListBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_FOLLOW_USER_PUBLIC_LIST_GUEST,
        params: params,
      });
      dispatch(getFollowUserPublicListSuccess(result));
      return result;
    } catch (error) {
      dispatch(getFollowUserPublicListFailure());
      return false;
    }
  };
}

// FETCH_TOP_BRANDS_USER
export function performFetchTopBrandsUser(data) {
  return async (dispatch) => {
    dispatch(fetchTopBrandsUserBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_TOP_BRANDS_USER,
        params: data,
      });
      dispatch(fetchTopBrandsUserSuccess(result));
      return result;
    } catch (error) {
      dispatch(fetchTopBrandsUserFailure());
      return false;
    }
  };
}

// FETCH_TOP_BRANDS_GUEST
export function performFetchTopBrandsGuest() {
  return async (dispatch) => {
    dispatch(fetchTopBrandsGuestBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_TOP_BRANDS_GUEST,
      });
      dispatch(fetchTopBrandsGuestSuccess(result));
      return result;
    } catch (error) {
      dispatch(fetchTopBrandsGuestFailure());
      return false;
    }
  };
}

export function performFetchRecommendedBrands(params = {}) {
  return async (dispatch) => {
    try {
      dispatch(fetchRecommendedBrandsBegin());
      
      const cacheKey = `${CACHE_KEYS.RECOMMENDED_BRANDS}-${JSON.stringify(params)}`;
      const cachedData = cacheService.get(cacheKey);
      
      if (cachedData) {
        dispatch(fetchRecommendedBrandsSuccess(cachedData.data));
        return cachedData;
      }
      
      const result = await GET({
        url: ENDPOINTS.FETCH_RECOMMENDED_BRANDS,
        params: {
          limit: params.limit || 10,
          page: params.page || 1
        }
      });

      if (result?.data) {
        cacheService.set(cacheKey, result);
        dispatch(fetchRecommendedBrandsSuccess(result.data));
        return result;
      }
      
      dispatch(fetchRecommendedBrandsFailure("No brands found"));
      return { status: false, error: "No brands found" };

    } catch (error) {
      console.error("Error fetching recommended brands:", error);
      dispatch(fetchRecommendedBrandsFailure(error.message));
      return { status: false, error: error.message };
    }
  };
}

// fetch For You User
export function performFetchForYouUser(data) {
  return async (dispatch) => {
    dispatch(fetchForYouUserBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_FOR_YOU_USER,
        params: data,
      });
      dispatch(fetchForYouUserSuccess(result));
      return result;
    } catch (error) {
      dispatch(fetchForYouUserFailure());
      return false;
    }
  };
}

// FETCH_FOR_YOU_GUEST

export function performFetchForYouGuest() {
  return async (dispatch) => {
    dispatch(fetchForYouGuestBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.FETCH_FOR_YOU_GUEST,
      });
      dispatch(fetchForYouGuestSuccess(result));
      return result;
    } catch (error) {
      dispatch(fetchForYouGuestFailure());
      return false;
    }
  };
}

// PERFORM TRACK ORDER
export function performUpdateTrackDetails(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    try {
      const result = await PATCH({
        url: ENDPOINTS.UPDATE_ORDER_TRACK_DETAILS(data.id),
        data: data.payload,
      });
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      return false;
    }
  };
}

// MARK_ITEM_PURCHASED
export function performMarkItemPurchased(data) {
  return async (dispatch) => {
    dispatch(markItemAsPurchasedBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.MARK_ITEM_PURCHASED,
        data,
      });
      dispatch(markItemAsPurchasedSuccess(result.data));
      return result.data;
    } catch (error) {
      dispatch(markItemAsPurchasedFailure());
      throw error;
    }
  };
}

// FETCH_GIFT_LIST
export function performFetchGiftList(params) {
  return async (dispatch) => {
    dispatch(fetchGiftListBegin());
    try {
      // console.log('Fetching gift list with params:', params);
      const result = await GET({
        url: ENDPOINTS.FETCH_GIFT_LIST,
        params: params,
      });
      // console.log('Gift list API response:', result);
      dispatch(fetchGiftListSuccess(result.data));
      return result.data;
    } catch (error) {
      // console.error('Error fetching gift list:', error); 
      dispatch(fetchGiftListFailure());
      return false;
    }
  };
}

export function performAddToGiftList(productId) {
  return async (dispatch) => {
    dispatch(addToGiftListBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.ADD_TO_GIFT_LIST,
        data: { productId },
      });
      dispatch(addToGiftListSuccess(result.data));
      dispatch(
        showSnack({
          message: result.message || "Item added to gift list successfully",
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(addToGiftListFailure(error));
      return false;
    }
  };
}

export function performRemoveFromGiftList(productId) {
  return async (dispatch) => {
    dispatch(removeFromGiftListBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.REMOVE_FROM_GIFT_LIST,
        data: { productId },
      });
      dispatch(removeFromGiftListSuccess(result.data));
      dispatch(
        showSnack({
          message: result.message || "Item removed from gift list successfully",
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(removeFromGiftListFailure(error));
      return false;
    }
  };
}

export function performGetProfileRecommendations(params) {
  return async (dispatch) => {
    dispatch(getProfileRecommendationsBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.GET_PROFILE_RECOMMENDATIONS,
        params: params,
      });
      dispatch(getProfileRecommendationsSuccess(result.data));
      return result;
    } catch (error) {
      dispatch(getProfileRecommendationsFailure(error));
      return false;
    }
  };
}

export function performFetchRecommendations(params = {}) {
  return async (dispatch) => {
    try {
      dispatch(fetchRecommendationsBegin());

      // Determine if this is a dashboard request and what type
      const isDashboard = params.isDashboard === true;
      const isWinterPicks = isDashboard && params.style_tags?.includes('Winter');
      const isForYou = isDashboard && !params.style_tags;

      // Set cache key for dashboard requests
      const cacheKey = isDashboard 
        ? isWinterPicks 
          ? `${CACHE_KEYS.WINTER_PICKS}-${JSON.stringify(params)}`
          : isForYou 
            ? `${CACHE_KEYS.FOR_YOU}-${JSON.stringify(params)}`
            : null
        : null;

      // Check cache for dashboard request
      if (cacheKey) {
        const cachedData = cacheService.get(cacheKey);
        if (cachedData) {
          dispatch(fetchRecommendationsSuccess(cachedData.data));
          return cachedData;
        }
      }

      // Request data
      const requestData = {
        n_recommendations: params.n_recommendations,
        categories: params.categories,
        subCategories: params.subCategories,
        genders: params.genders
      };

      // Add style_tags 
      if (params.style_tags) {
        requestData.style_tags = Array.isArray(params.style_tags) 
          ? params.style_tags 
          : [params.style_tags];
      }

      const result = await POST({
        url: ENDPOINTS.FETCH_RECOMMENDATIONS,
        data: requestData
      });

      if (result?.data) {
        // Only cache for dashboard request
        if (cacheKey) {
          cacheService.set(cacheKey, result);
        }

        const { docs = [], totalDocs = 0, ...rest } = result.data;
        dispatch(fetchRecommendationsSuccess({ docs, totalDocs, ...rest }));
        return {
          status: true,
          data: { recommendations: docs }
        };
      }

      dispatch(fetchRecommendationsFailure("No recommendations found"));
      return { status: false, error: "No recommendations found" };
    } catch (error) {
      console.error("Error fetching recommendations:", error);
      dispatch(fetchRecommendationsFailure(error.message));
      return { status: false, error: error.message };
    }
  };
}

export function performGetExploreProducts(filters = {}, page = 1, limit = 30) {
  return async (dispatch) => {
    dispatch(fetchAllProductBegin());
    
    try {
      // Clean filters 
      const cleanFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          acc[key] = value;
        } else if (value && !Array.isArray(value)) {
          acc[key] = value;
        }
        return acc;
      }, {});

      // Create cache key including pagination params
      const cacheKey = `explore-${JSON.stringify({
        filters: cleanFilters,
        page,
        limit
      })}`;

      // Check cache first
      const cachedData = cacheService.get(cacheKey);
      if (cachedData) {
        console.log('Returning cached explore data for:', {
          filters: cleanFilters,
          page,
          limit
        });
        dispatch(fetchAllProductSuccess(cachedData));
        return cachedData;
      }

      console.log('Fetching fresh explore data for:', {
        filters: cleanFilters,
        page,
        limit
      });

      const params = {
        ...cleanFilters,
        page,
        limit,
      };

      const response = await GET({
        url: "/explore/explore",
        params
      });

      const data = response?.data || [];

      // Only cache if we have data 
      if (data?.docs?.length > 0) {
        const responseData = {
          docs: data.docs || [],
          page: data.page || 1,
          limit: data.limit || limit,
          totalDocs: data.totalDocs || 0,
          hasNextPage: data.hasNextPage || false
        };
        
        cacheService.set(cacheKey, responseData);
        dispatch(fetchAllProductSuccess(responseData));
        return responseData;
      }

      // Dispatch success but don't cache empty results
      dispatch(fetchAllProductSuccess({
        docs: [],
        page: 1,
        limit,
        totalDocs: 0,
        hasNextPage: false
      }));

      return data;
    } catch (error) {
      console.error('Error in performGetExploreProducts:', error);
      dispatch(fetchAllProductFailure());
      return false;
    }
  };
}

export function performSearchUniversities(query) {
  return async (dispatch) => {
    dispatch(searchUniversitiesBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.SEARCH_UNIVERSITIES,
        params: { query }
      });
      dispatch(searchUniversitiesSuccess(result.data));
      return result.data;
    } catch (error) {
      dispatch(searchUniversitiesFailure(error));
      return false;
    }
  };
}