import {
  updateProfileBegin,
  updateProfileSuccess,
  updateProfileFailure,
  updatePasswordBegin,
  updatePasswordSuccess,
  updatePasswordFailure,
  changeProfilePasswordBegin,
  changeProfilePasswordSuccess,
  changeProfilePasswordFailure,
  searchUserByName,
  FetchProfileByIDBegin,
  FetchProfileByIDSuccess,
  FetchProfileByIDFailure,
  followUnfollowUser,
} from "../actions/accountActions";
import { updateUserData } from "../actions/authActions";
import { showSnack, resetSnack } from "../actions/alertActions";

import ENDPOINTS, { GET, POST, PUT, PATCH } from "../../services/requests";

export function performUpdateProfile(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(updateProfileBegin());
    try {
      const result = await PUT({ url: ENDPOINTS.UPDATE_PROFILE, data: data });
      dispatch(updateUserData(result.data));
      dispatch(updateProfileSuccess());

      return true;
    } catch (error) {
      dispatch(updateProfileFailure());
      return false;
    }
  };
}

export function performUpdatePassword(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(updatePasswordBegin());
    try {
      const result = await POST({ url: ENDPOINTS.UPDATE_PASSWORD, data: data });

      dispatch(updatePasswordSuccess());
      dispatch(
        showSnack({
          message: result.data,
          options: {
            variant: "success",
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(updatePasswordFailure());
      return false;
    }
  };
}

//CHANGE_PROFILE_PASSWORD

export function performChangeProfilePassword(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(changeProfilePasswordBegin());
    try {
      const result = await PATCH({
        url: ENDPOINTS.CHANGE_PROFILE_PASSWORD,
        data,
      });
      dispatch(changeProfilePasswordSuccess());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      dispatch(changeProfilePasswordFailure());
      return false;
    }
  };
}

// SEARCH_USER_BY_ID

export function performFetchProfileByID(id) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(FetchProfileByIDBegin());
    try {
      const result = await GET({
        url: ENDPOINTS.SEARCH_USER_BY_ID(id),
      });
      dispatch(FetchProfileByIDSuccess());

      return result;
    } catch (error) {
      dispatch(FetchProfileByIDFailure());
      return false;
    }
  };
}

// FOLLOW_UNFOLLOW_USER
export function performfollowUnfollowUser(data) {
  return async (dispatch) => {
    try {
      const result = await POST({
        url: ENDPOINTS.FOLLOW_UNFOLLOW_USER,
        data,
      });
      dispatch(followUnfollowUser());
      return result;
    } catch (error) {
      return false;
    }
  };
}

// LIST FOLLOW and Following USERS
export function getListFollowfollowingUser(params) {
  return async (dispatch) => {
    try {
      const result = await GET({
        url: ENDPOINTS.FOLLOW_UNFOLLOW_USER,
        params: params,
      });
      dispatch(followUnfollowUser());
      return result;
    } catch (error) {
      return false;
    }
  };
}
// SEARCH_USER_BY_NAME
export function performSearchUserByName(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(searchUserByName());
    try {
      const result = await GET({
        url: ENDPOINTS.SEARCH_USER_BY_NAME,
        params: data,
      });
      return result;
    } catch (error) {
      return false;
    }
  };
}

// Mobile verification otp
export function performMobileVerificationOTP(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    try {
      const result = await POST({
        url: ENDPOINTS.VERIFY_MOBILE_OTP,
        data: data,
      });
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      return false;
    }
  };
}
// Mobile verification
export function performMobileVerification(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    try {
      const result = await PUT({
        url: ENDPOINTS.VERIFY_MOBILE,
        data: data,
      });
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      return false;
    }
  };
}

// GET EMAILS
export function performGetMails() {
  return async (dispatch) => {
    dispatch(resetSnack());
    try {
      const result = await GET({
        url: ENDPOINTS.USER_MAILS,
      });

      return result;
    } catch (error) {
      return false;
    }
  };
}

// PERFORM ADD EMAILS
export function performAddMails(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    try {
      const result = await POST({
        url: ENDPOINTS.USER_MAILS,
        data: data,
      });

      return result;
    } catch (error) {
      return false;
    }
  };
}
