import {
  loginBegin,
  loginFailure,
  loginSuccess,
  emailExistCheckBegin,
  forgotPasswordBegin,
  resetPasswordVerifyBegin,
  resetPasswordVerifySuccess,
  resetPasswordVerifyFailure,
  resetPasswordBegin,
  resetPasswordSuccess,
  resetPasswordFailure,
  UploadProfileImageBegin,
  UploadProfileImageSuccess,
  UploadProfileImageFailure,
  VerifyOtpBegin,
  VerifyOtpFailure,
  VerifyOtpSuccess,
  setSubmitting,
  getProfileDetailsBegin,
  getProfileDetailsSuccess,
  getProfileDetailsFailure,
  profileUpdateBegin,
  profileUpdateSuccess,
  profileUpdateFailure,
  verifyEmailBegin,
  verifyEmailSuccess,
  verifyEmailFailure,
  socialLoginFailure,
  socialLoginSuccess,
  socialLoginBegin,
  socialSignupBegin,
  socialSignupSuccess,
  socialSignupFailure,
  deleteProfileBegin,
  deleteProfileSuccess,
  deleteProfileFailure,
  deactivateProfileBegin,
  deactivateProfileSuccess,
  deactivateProfileFailure,
  usernameExistCheckBegin,
  resendOTPBegin,
  resendOTPSuccess,
  resendOTPFailure,
  logout,
} from "../actions/authActions";
import { showSnack, resetSnack } from "../actions/alertActions";
import cacheService from "../../services/cacheContext";

import ENDPOINTS, { GET, POST, PUT } from "../../services/requests";

export function performLogin({ email, mobile, password, username }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(loginBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.LOGIN,
        data: {
          email,
          mobile,
          password,
          username,
        },
      });
      dispatch(
        loginSuccess({
          token: result.data.tokens,
          user: result.data.user,
          role: null,
          isAuthenticated: ["completed", "step-2"].includes(
            result.data.user.onboardingStep
          ),
        })
      );

      dispatch(
        showSnack({
          message: "Logged in successfully!",
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      dispatch(loginFailure());
      return false;
    }
  };
}

// Need to fix the error
export function performSocialLogin(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(socialLoginBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.SOCIAL_LOGIN,
        data,
      });

      dispatch(
        socialLoginSuccess({
          token: result.data.tokens,
          user: result.data.user,
          role: null,
          isAuthenticated: ["completed", "step-2"].includes(
            result.data.user.onboardingStep
          ),
        })
      );
      dispatch(
        showSnack({
          message: "Logged in successfully!",
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      dispatch(socialLoginFailure(error));
      return false;
    }
  };
}

export function performVerifyOtp(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(setSubmitting(true));
    dispatch(VerifyOtpBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.VERIFY_OTP,
        data: data,
      });

      if (!result.status) {
        dispatch(
          showSnack({
            message: "Something went wrong!",
            options: {
              variant: "error",
            },
          })
        );
      } else {
        dispatch(
          VerifyOtpSuccess({
            token: result.data.tokens,
          })
        );

        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "success",
            },
          })
        );
      }

      return result;
    } catch (error) {
      dispatch(VerifyOtpFailure());
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

// Email Exist Check
export function performEmailExistCheck(data) {
  return async (dispatch) => {
    dispatch(emailExistCheckBegin(true));
    dispatch(setSubmitting(true));
    try {
      const result = await POST({ url: ENDPOINTS.EMAILEXIST, data });
      return result?.data;
    } catch (error) {
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

// Username Exist Check
export function performUsernameExistCheck(data) {
  return async (dispatch) => {
    dispatch(usernameExistCheckBegin(true));
    dispatch(setSubmitting(true));
    try {
      const result = await POST({ url: ENDPOINTS.USERNAME_EXIST, data });
      return result?.data;
    } catch (error) {
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performUploadProfileImage(data) {
  return async (dispatch) => {
    dispatch(UploadProfileImageBegin(true));
    dispatch(setSubmitting(true));
    try {
      const result = await POST({
        url: ENDPOINTS.UPLOAD_PROFILE_IMAGE,
        data,
      });
      if (!result.status) {
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "error",
            },
          })
        );
      } else {
        dispatch(
          UploadProfileImageSuccess({
            image: result?.data?.profileImageUrl,
          })
        );

        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "success",
            },
          })
        );
      }
      return result;
    } catch (error) {
      dispatch(UploadProfileImageFailure());
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performSignup({ email, mobile, password, referralCode, accessCode }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(setSubmitting(true));
    try {
      const result = await POST({
        url: ENDPOINTS.SIGNUP,
        data: {
          email,
          mobile,
          password,
          referralCode,
          accessCode,
        },
      });

      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performVerifyEmail(data) {
  return async (dispatch) => {
    dispatch(setSubmitting(true));
    dispatch(verifyEmailBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.VERIFY_EMAIL,
        data: {
          token: data,
        },
      });
      const token = {
        access: result.data.access,
        refresh: result.data.refresh,
      };
      dispatch(
        verifyEmailSuccess({
          token: token,
        })
      );
      return result;
    } catch (error) {
      dispatch(verifyEmailFailure());
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performSocialSignup(data) {
  return async (dispatch) => {
    dispatch(setSubmitting(true));
    dispatch(socialSignupBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.SOCIAL_SIGNUP,
        data,
      });
      // const token = {
      //   access: result.data.token.access,
      //   refresh: result.data.token.refresh,
      // };
      dispatch(
        socialSignupSuccess({
          token: result.data.tokens,
        })
      );
      return result;
    } catch (error) {
      dispatch(socialSignupFailure(error));
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performForgotPassword(data) {
  return async (dispatch) => {
    dispatch(forgotPasswordBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.FORGOT_PASSWORD,
        data,
      });
      if (!result) {
        return;
      } else {
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "success",
            },
          })
        );
      }
      return result;
    } catch (error) {
      return false;
    }
  };
}

export function performResetPasswordVerify(data) {
  return async (dispatch) => {
    dispatch(setSubmitting(true));
    dispatch(resetPasswordVerifyBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.RESET_PASSWORD_VERIFY,
        data,
      });

      dispatch(
        resetPasswordVerifySuccess({
          token: result.data.tokens,
        })
      );
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      dispatch(resetPasswordVerifyFailure(error));
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performResetPassword(data) {
  return async (dispatch) => {
    dispatch(setSubmitting(true));
    dispatch(resetPasswordBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.RESET_PASSWORD,
        data: {
          password: data.password,
        },
      });

      dispatch(resetPasswordSuccess({ token: null }));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(resetPasswordFailure(error));
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performResendOTP(data) {
  return async (dispatch) => {
    dispatch(resendOTPBegin());
    dispatch(resetSnack());
    try {
      const result = await POST({
        url: ENDPOINTS.RESEND_OTP,
        data: data,
      });
      dispatch(resendOTPSuccess(result));
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );
      return result;
    } catch (error) {
      dispatch(resendOTPFailure(error));
      return false;
    }
  };
}

export function performGetProfileDetails(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(getProfileDetailsBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_PROFILE });
      dispatch(getProfileDetailsSuccess(result.data));
      return true;
    } catch (error) {
      dispatch(getProfileDetailsFailure());
      return false;
    }
  };
}

export function performProfileUpdate(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(profileUpdateBegin());
    try {
      const result = await PUT({
        url: ENDPOINTS.UPDATE_PROFILE,
        data,
      });
      if (!result.status) {
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "error",
            },
          })
        );
      } else {
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "success",
            },
          })
        );
        dispatch(profileUpdateSuccess(result.data));
      }

      return result;
    } catch (error) {
      dispatch(profileUpdateFailure());
      return false;
    }
  };
}

//deactivate Profile

export function performDeactivateProfile(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(setSubmitting(true));
    dispatch(deactivateProfileBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.DEACTIVATE_PROFILE,
        data,
      });
      if (!result.status) {
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "error",
            },
          })
        );
      } else {
        dispatch(deactivateProfileSuccess());
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "success",
            },
          })
        );
      }
      return result;
    } catch (error) {
      dispatch(deactivateProfileFailure(error));
      dispatch(
        showSnack({
          message: error,
          options: {
            variant: "error",
          },
        })
      );
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

//delete Profile

export function performDeleteProfile(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(setSubmitting(true));
    dispatch(deleteProfileBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.DELETE_PROFILE,
        data,
      });
      if (!result.status) {
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "error",
            },
          })
        );
      } else {
        dispatch(deleteProfileSuccess());
        dispatch(
          showSnack({
            message: result.message,
            options: {
              variant: "success",
            },
          })
        );
      }
      return result;
    } catch (error) {
      dispatch(deleteProfileFailure(error));
      dispatch(
        showSnack({
          message: error,
          options: {
            variant: "error",
          },
        })
      );
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performLogout({ refresh_token }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    try {
      const result = await POST({
        url: ENDPOINTS.LOGOUT,
        data: {
          refresh_token,
        },
      });

      // Clear  cache
      cacheService.clear();

      dispatch(logout());
      dispatch(
        showSnack({
          message: result.message,
          options: {
            variant: "success",
          },
        })
      );

      return result;
    } catch (error) {
      cacheService.clear(); 
      dispatch(logout());
      return false;
    }
  };
}