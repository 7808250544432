import { useCallback, useState } from "react";
import { Card, Col, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "../Form/Input";
import { userListRules } from "../../services/validations";

import TextArea from "../Form/TextArea";
import { CloseCrossIcon } from "../../assets/icons";

export const CreateNewListModal = ({
  showModal,
  handleClose,
  guest,
  loggedInUser,
  getYourLists,
  CreateYourList,
  fetching,
  setSidebarLists,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: userListRules,
    defaultValues: {
      title: "",
      isPublic: false,
      description: "",
      tags: [],
    },
  });

  const [tags] = watch(["tags", "title"]);
  const [page] = useState(1);

  const fetchSidebarLists = useCallback(async () => {
    if (guest) return;
  
    let data = { page };
  
    try {
      const result = await getYourLists(data);
      if (result?.data?.docs) {
        const formattedData = result.data.docs.map((item) => ({
          title: item?.title || '',
          id: item?._id || '',
          isPublic: item?.isPublic || false,
          isActive: item?.isActive || false,
          isOwnList: item?.userId === loggedInUser?._id,
          ownerName: item?.ownerName || '',
          isLiked: item?.isLiked || false,
          productsCount: item?.productsCount || 0,
          images: item?.images || [] 
        }));
  
        setSidebarLists(formattedData);
      } else {
        setSidebarLists([]); 
        console.error("Unexpected API response structure:", result);
      }
    } catch (error) {
      setSidebarLists([]); 
      console.error("Error fetching lists:", error);
    }
  }, [getYourLists, guest, loggedInUser?._id, page, setSidebarLists]);

  //Private / Public  select
  const [isOn, setIsOn] = useState(true);
  //   const handleToggle = () => setIsOn(!isOn);

  const onSubmit = async (data) => {
    try {
      let selectedTags = tags?.map((item) => item.value) || [];
  
      let newFormData = {
        title: data?.title,
        isPublic: isOn,
        tags: selectedTags,
        description: data?.description
      };
  
      const result = await CreateYourList(newFormData);
      
      if (result?.status) {
        handleClose();
        await fetchSidebarLists();
        setIsOn(false);
        reset();
      } else {
        console.error('List creation failed:', result?.error || 'Unknown error');
      }
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  const handleCloseModal = () => {
    handleClose();
    setIsOn(false);
    reset();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Body className="w-100">
        <div className="list-popup-card">
          <Col className="align-self-end mb-1" sx={12}>
            <CloseCrossIcon onClick={handleClose} />
          </Col>
          <Col className="align-self-center mb-4" sx={12}>
            <p className="popup-title-text">Create New List</p>
          </Col>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Col xs={12} className="mb-3">
                <span className="label-text">List Title*</span>

                <Input
                  type="text"
                  id="title"
                  name="title"
                  label="Blue Jeans"
                  className="with-border"
                  {...register("title", { required: true })}
                  error={errors.title?.message}
                />
              </Col>
              <Col xs={12} className="mb-3">
                <TextArea
                  textareaClassProps="popup"
                  label="Description"
                  id="description"
                  name="description"
                  placeholder="Jeans for all year round - enjoy!"
                  {...register("description", { required: false })}
                />
              </Col>
              {/* <Col xs={12} className="isPublic-toggle my-4">
                <p>Public</p>
                <div
                  className={`toggle-switch ${isOn ? "on" : "off"}`}
                  onClick={() => handleToggle(!isOn)}
                >
                  <div
                    className={`toggle-switch-circle ${isOn ? "on" : "off"}`}
                  />
                </div>
              </Col> */}
              <Col xs={12} className="mt-5">
                <button
                  type="submit"
                  className={`form-submit-button black ${
                    isValid ? "" : "invalid"
                  }`}
                  disabled={fetching}
                >
                  {fetching ? (
                    <Spinner animation="border" />
                  ) : (
                    <span>Create</span>
                  )}
                </button>
              </Col>
            </Form>
          </Card.Body>
        </div>
      </Modal.Body>
    </Modal>
  );
};
